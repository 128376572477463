export const confidenceAgreement: string = `
Настоящая Политика конфиденциальности (далее – Политика) устанавливает правила использования персональной информации, получаемой от пользователей сайта (далее – Пользователи) администратором сайта http://any-clean.ru/, принадлежащего Тихоновой Юлии Евгеньевне, ИНН 244803500665 (далее — Владелец сайта, Администратор сайта). Настоящая Политика конфиденциальности применяется ко всем Пользователям Сайта. Все термины и определения, встречающиеся в тексте Политики толкуются в соответствии с действующим законодательством РФ (в частности, ФЗ «О персональных данных».) 

Пользователи прямо соглашаются на обработку своих персональных данных, как это описано в настоящей Политике. Использование Сайта означает выражение Пользователем безоговорочного согласия с Политикой и указанными условиями обработки информации. 

Пользователь не должен пользоваться Сайтом, если Пользователь не согласен с условиями Политики. 

1. Персональная информация Пользователей, которую обрабатывает Администратор 

Сайт собирает, получает доступ и использует в определенных Политикой целях персональные данные Пользователей, техническую и иную информацию, связанную с Пользователями. 
Техническая информация не является персональными данными. Администратор сайта использует файлы cookies, которые позволяют идентифицировать Пользователя. Файлы cookies – это текстовые файлы для обработки информации об активности Пользователя, включая информацию о том, какие страницы посещал Пользователь и о времени, которое Пользователь провел на странице. Пользователь может отключить возможность использования файлов cookies в настройках браузера. 
Также под технической информацией понимается информация, которая автоматически передается Администратору в процессе использования Сайта с помощью установленного на устройстве Пользователя программного обеспечения. 
Под персональными данными Пользователя понимается информация, которую Пользователь предоставляет Администратору сайта при заполнении заявки на Сайте и последующем использовании Сайта. Обязательная для предоставления Администратору сайта информация помечена специальным образом. Иная информация предоставляется Пользователем на его усмотрение. 
Администратор сайта также может обрабатывать данные, сделанные общедоступными субъектом персональных данных или подлежащие опубликованию или обязательному раскрытию в соответствии с законом. 
Администратор сайта не проверяет достоверность персональной информации, предоставляемой Пользователем, и не имеет возможности оценивать его дееспособность. Однако Администратор сайта исходит из того, что Пользователь предоставляет достоверную и достаточную персональную информацию о себе и поддерживает эту информацию в актуальном состоянии. 

2. Цели обработки персональной информации Пользователей. 

Главная цель Администратора сайта при сборе персональных данных — предоставление информационных, консультационных услуг Пользователям. Пользователи соглашаются с тем, что Администратор сайта также может использовать их персональные данные для: 
Идентификация стороны в рамках предоставляемых услуг; 
Предоставления услуг и клиентской поддержки по запросу Пользователей; 
Улучшение качества услуг, удобства их использования, разработка и развитие Сайта, устранение технических неполадок или проблем с безопасностью; 
Анализ для расширения и совершенствования услуг, информационного наполнения и рекламы услуг; 
Информирование Пользователей об услугах, целевом маркетинге, обновлении услуг и рекламных предложениях на основании информационных предпочтений Пользователей; 
Таргетирование рекламных материалов; рассылка индивидуальных маркетинговых сообщений посредством электронной почты, звонков и SMS; 
Проведение статистических и иных исследований на основе обезличенных данных; 
Администратор сайта использует техническую информацию обезличено в целях, указанных в пункте 2.1

3. Условия и способы обработки персональной информации Пользователей и её передачи третьим лицам. 

Пользователь дает согласие на обработку своих персональных данных путём отправки заявки (любой письменный запрос, содержащий контактные данные). 
Обработка персональных данных Пользователя означает сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных Пользователя. 
В отношении персональной информации Пользователя сохраняется её конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц. 
Администратор сайта вправе передать персональную информацию Пользователя третьим лицам в следующих случаях: 
Пользователь выразил согласие на такие действия; 
Передача необходима для использования Пользователем определённой услуги Сайта либо для исполнения определённого договора или соглашения с Пользователем; 
Передача уполномоченным органам государственной власти Российской Федерации по основаниям и в порядке, установленным законодательством Российской Федерации; 
В целях обеспечения возможности защиты прав и законных интересов Владельца сайта или третьих лиц в случаях, когда Пользователь нарушает условия договоров и соглашений с Владельцем сайта, настоящую Политику либо документы, содержащие условия использования конкретных услуг; 
В результате обработки персональной информации Пользователя путём её обезличивания получены обезличенные статистические данные, которые передаются третьему лицу для проведения исследований, выполнения работ или оказания услуг по поручению Владельца сайта. 

4. Меры, применяемые для защиты персональной информации Пользователя. 

Владелец сайта принимает необходимые и достаточные правовые, организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц. 

5. Разрешение споров. 

Все возможные споры, вытекающие из отношений, регулируемых настоящей Политикой, разрешаются в порядке, установленном действующим законодательством Российской Федерации, по нормам российского права. 
Соблюдение досудебного (претензионного) порядка урегулирования споров является обязательным. 

6. Дополнительные условия.

Владелец сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя. 
Новая Политика конфиденциальности вступает в силу с момента её размещения, если иное не предусмотрено новой редакцией Политики конфиденциальности. 
Продолжение использования Сайта после внесения таких изменений подтверждает согласие Пользователя с такими изменениями.
`;

export const personalAgreement: string = `
Настоящим Я, действуя своей волей и в своем интересе, при размещении (вводе) своих персональных данных на Интернет сайте any-clean.ru , принадлежащем Тихоновой Юлии Евгеньевне, ИНН 244803500665 (далее - Оператор), подтверждаю свое согласие на обработку указанных мной персональных данных Оператором в целях предложения мне услуг, новых услуг, предлагаемых Оператором, в целях проведения опросов, анкетирования, рекламных и маркетинговых исследований в отношении услуг, предоставляемых Оператором, в том числе путем осуществления прямых контактов со мною посредством средств связи, указанных мной на настоящем сайте.Настоящее право (согласие) предоставляется на осуществление любых действий в отношении моих персональных данных, которые необходимы и желаемы для достижения вышеуказанных целей, включая, без ограничения, сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, передачу, обезличивание, блокирование и уничтожение персональных данных, под которыми понимаются все данные, указанные мной на настоящем сайте.
Настоящим подтверждаю, что уведомлен о том, что обработка персональных данных осуществляется Оператором любым способом, в том числе как с использованием средств автоматизации (включая программное обеспечение), так и без использования средств автоматизации (с использованием различных материальных носителей, включая бумажные носители).
`;
