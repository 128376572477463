interface Question {
  question: string;
  answer: string;
};

export const questionsData: Question[] = [
  {
    question: "За какое время оформляется заказ?",
    answer: "Заявку на уборку желательно оставлять за 1 - 2 дня. Если же требуется срочный выезд, мы приложим максимум усилий, чтобы выполнить заказ в самые сжатые сроки при наличии свободной бригады.",
  },
  {
    question: "Как долго длится уборка?",
    answer: "Время уборки жилого помещения - от 2 до 6 часов. Коммерческое или промышленное помещение будет убираться в срок - в зависимости от вида работ и метража.",
  },
  {
    question: "Как я могу оплатить ваши услуги?",
    answer: "Наличными деньгами или через Сбербанк Онлайн. Для юр.лиц предусмотрен безналичный расчёт.",
  },
  {
    question: "Должен ли хозяин квартиры предоставить инвентарь и моющие средства?",
    answer: "Нет. Всё необходимое наши сотрудники привезут с собой. Уборка производится посредством профессионального оборудования и с использованием специализированных, сертифицированных моющих средств.",
  },
  {
    question: "Обязательно ли присутствие хозяев квартиры на время уборки?",
    answer: "Вы можете идти по делам, оставив клинера наводить чистоту в вашем помещении. И вернуться к завершению уборки, чтобы принять работу. У нас есть дополнительная услуга по доставке ключей. Клинер приедет и заберёт ключи перед уборкой. А по завершении её - привезёт обратно.",
  },
  {
    question: "Какими моющими средствами Ваши клинеры пользуются при уборке?",
    answer: "Мы работаем с сертифицированными, профессиональными средствами, гипоаллергенными и безопасными для людей и их питомцев. В работе используется химия торговых марок Grass и Probrite.",
  },
  {
    question: "У меня ничего не украдут?",
    answer: "Вы можете быть спокойны! Каждый наш клинер при приёме на работу проходит жёсткий отбор и подписывает договор о материальной ответственности. Наши специалисты дорожат честным именем и репутацией компании.",
  },
];
